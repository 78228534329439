import moment from "moment";
import { readable, derived } from "svelte/store";

export const time = readable(
    moment(),
    function start (set) {
        const interval = setInterval(() => {
            set(moment());
        }, 1000);

        return function stop() {
            clearInterval(interval);
        };
    }
);

function diffCounter(now, from) {
    let elapsed = moment.duration(now.diff(from));

    return {
        date: moment(from).format("ddd D. MMM YYYY"),
        years: Math.abs(elapsed.years()),
        months: Math.abs(elapsed.months()),
        weeks: Math.abs(now.diff(from, "weeks")),
        days: Math.abs(elapsed.days()),
        hours: Math.abs(elapsed.hours()),
        minutes: Math.abs(elapsed.minutes()),
        seconds: Math.abs(elapsed.seconds())
    };
}

export const firstDateElapsed = derived(
    time,
    $time => {
        return diffCounter($time, "2017-01-25T18:00:00+01:00");
    }
);

export const engagementElapsed = derived(
    time,
    $time => {
        return diffCounter($time, "2019-01-26T15:00:00+01:00");
    }
);

export const marriageElapsed = derived(
    time,
    $time => {
        return diffCounter($time, "2020-05-09T15:00:00+01:00");
    }
);

export const expectingElapsed = derived(
    time,
    $time => {
        return diffCounter($time, "2020-08-31T00:00:00+01:00");
    }
);

export const maternityLeavePredictionElapsed = derived(
    time,
    $time => {
        return diffCounter($time, "2021-04-28T00:00:00+01:00");
    }
);

export const bornPredictionElapsed = derived(
    time,
    $time => {
        return diffCounter($time, "2021-06-09T00:00:00+01:00");
    }
);
