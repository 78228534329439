import moment from "moment";
import App from "./App.svelte";

moment.locale('sk');

const app = new App({
	target: document.body
});

export default app;
