<script>
	import Tailwindcss from "./Tailwindcss.svelte";
    import {
        time,
        firstDateElapsed,
        engagementElapsed,
        marriageElapsed,
        expectingElapsed,
        maternityLeavePredictionElapsed,
        bornPredictionElapsed
    } from "./stores.js";
    import Counter from "./Counter.svelte";
</script>

<Tailwindcss />

<div class="container mx-auto flex flex-col ustify-center">
    <Counter
        elapsed={$bornPredictionElapsed}
        emoji={"👶"}
        showWeeks={true}
        showDate={true} />
    <Counter
        elapsed={$maternityLeavePredictionElapsed}
        emoji={"🏠"}
        showWeeks={true}
        showDate={true} />
    <Counter
        elapsed={$expectingElapsed}
        emoji={"❤️"}
        showWeeks={true} />
    <Counter
        elapsed={$marriageElapsed}
        emoji={"💒"}
        showDate={true} />
    <Counter
        elapsed={$engagementElapsed}
        emoji={"💍"}
        showDate={true} />
    <Counter
        elapsed={$firstDateElapsed}
        emoji={"👫"}
        showDate={true} />
</div>
