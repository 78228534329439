<script>
    export let elapsed;
    export let emoji;
    export let showWeeks = false;
    export let showDate = false;
</script>

<div class="flex flex-col items-center">
    <div class="mt-8">
        <table class="w-auto">
            <tbody>
                <tr>
                {#if showWeeks}
                    <td class="w-4 pb-1 align-bottom">({elapsed.weeks}&nbsp;w)</td>
                {:else}
                    <td class="w-10 text-2xl text-right pr-1 align-bottom">{elapsed.years}</td>
                    <td class="w-4 pb-1 align-bottom">y</td>
                {/if}
                    <td class="w-10 text-2xl text-right pr-1 align-bottom">{elapsed.months}</td>
                    <td class="w-4 pb-1 align-bottom">M</td>

                    <td class="w-10 text-2xl text-right pr-1 align-bottom">{elapsed.days}</td>
                    <td class="w-4 pb-1 align-bottom">d</td>

                    <td class="w-10 text-2xl text-right pr-1 align-bottom">{elapsed.hours}</td>
                    <td class="w-4 pb-1 align-bottom">h</td>

                    <td class="w-10 text-2xl text-right pr-1 align-bottom">{elapsed.minutes}</td>
                    <td class="w-4 pb-1 align-bottom">m</td>

                    <td class="w-10 text-2xl text-right pr-1 align-bottom">{elapsed.seconds}</td>
                    <td class="w-4 pb-1 align-bottom">s</td>
                </tr>
            </tbody>
        </table>
    </div>
{#if showDate}
    <div class="">{elapsed.date}</div>
{/if}
{#if emoji}
    <div class="text-2xl">{emoji}</div>
{/if}
</div>
